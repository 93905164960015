<template>
  <div class="login-view">
    <div class="login-bg login-bg-show"></div>
    <div class="login-content login-content-show" v-loading="loading">
      <div class="login-title">智慧阳光后勤+营养餐可视化</div>
      <Form  class="formView" ref="loginFormRef" :model="formData">
        <FormItem 
          class="form-item"
          prop="username"
          :rules="[
            { required: true, message: '请输入账号', trigger: 'blur' },
          ]"
        >
          <Input
            v-model="formData.username"
            type="text"
            autocomplete="off"
            placeholder="请输入账号"
            class="input-inner"
          />
        </FormItem>
        <FormItem 
          class="form-item"
          style="margin-top: 18.88px"
          prop="password"
          :rules="[{ required: true, message: '请输入密码', trigger: 'blur' }]"
        >
          <Input
            v-model="formData.password"
            type="password"
            autocomplete="off"
            placeholder="请输入密码"
            class="input-inner"
          />
        </FormItem>
      </Form>
      <div class="login-btn" @click="handelLogin()">登录</div>
    </div>
  </div>
</template>

<script>
import { login } from "@/api";
export default {
  name: "LoginView",
  data() {
    return {
      loading: false,
      formData: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    handelLogin() {
    
      this.$refs.loginFormRef.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            const resData = await login(this.formData);
            this.$Message.success("登录成功");
            this.loading = false;
            this.$store.commit("SET_TOKEN", {
              token: resData.data.access_token,
            });
          } finally {
            this.loading = false;
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.login-view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  .login-bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    opacity: 0;
    transition: all 0.5s;
    &.login-bg-show {
      opacity: 1;
    }
  }
  .login-content {
    background-color: white;
    border-radius: 15px;
    box-sizing: border-box;
    box-shadow: 0 3px 6px rgba(0,0,0,.15);

    overflow: hidden;
    transition: all 0.5s ease-out;
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 43%;
    width: 587px;
    height: 472px;
    transform: translateX(-50%) translateY(-100vh);
    display: flex;
    align-items: center;
    justify-content: center;

    &.login-content-show {
      transform: translateX(-50%) translateY(-50%);
      opacity: 1;
    }
    .login-title {
      position: absolute;
      color: black;
      top: 56px;
      font-size: 26px;
    }
    .formView {
      position: absolute;
      width: 353.33px;
      top: 143.96px;
      .form-item {
        height: 61.36px;
        margin-bottom: unset !important;
        ::v-deep .el-form-item__content,
        ::v-deep .el-input {
          transition: border .2s ease-in-out, background .2s ease-in-out, box-shadow .2s ease-in-out;

        }
        ::v-deep .el-input__inner {
          height: 100%;
          background-color: transparent !important;
          padding-left: 47px;
          outline: none;
          border: none;
          font-size: 14px;
          color: #00c4b7;
        }
        ::v-deep .ivu-input {
          display: inline-block;
          width: 100%;
          height: 47px;
          line-height: 1.5;
          font-size: 12px;
          border-radius: 8px;
          color: #515a6e;
          background-color: #fff;
          background-image: none;
          position: relative;
          cursor: text;
          transition: border .2s ease-in-out, background .2s ease-in-out, box-shadow .2s ease-in-out;
        }
      }
    }
    .login-btn {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAtgAAAB+BAMAAADsJ3vsAAAALVBMVEUAxLcAAAAAw7YAxbcAxLgAxbgAxLcAxLcAw7cAw7cAxbgAxLgAyLEAxbYAxLdstYvuAAAADnRSTlOHAKXAQeDDtICRz+0XZSNutIUAAAM6SURBVHja7d3NaRtBGIfxFwXLWALBJnZsJ5aJU8CCyCE3wzZgkgaWVCCSBhbUgIkaEFEDIm5AoAYMbsBEH46lSNoasjuSdds4l/nDwvMr4TnNOzM7a0GmmcKvZZBzsY9SeBbtYl/9MniVfNvF7v0weNVb7WLH7wxe1ddPsc9Sg1/7k6fYzT8Gvw6WT7FfPxo8G0bb2LcLg2eD7jb21SeDZ0l/Gzv8avCsscpjs/KTqK3z2Kz8JF5MNrGbDwbfKg+b2Kes/ASGIxf7zcTgXRy52Lfs+Qm0u1lsltkaYd/Fvv5i8K4xdrEvmWkE6jMXO/5u8M1NNcZMo1Fd5LEPmWkUKtM89glHBwoHaR77mAFSYhhlsY8YICXiu8AYIEUuu4ExQIqErSz2PbElws+BMa1LuHndOIEUqc+y2JecQErs5bGHbI1I1OZZbLZGNKqLwNgaEalMiS3iztft5LdBIg3slNgi6chesQ8lEkfEFnGxuaKjEt/ZOTusIu0OsUVcbM4OVJIL4+xAJWwRW8TFvudTapHejXFQo9IYE1vExU44FROpz4xTMZU9YhcidpkRuxixy4zYxYhdZsQWqq2NOzoq1TmxixC7zIhdjNhlRuxixC4zYsu42Hx4IOFiM0H+E+N6SRG7GLHLjNjFiF1mxC5G7DLLYve4ESVSX3HX7xlcrCylxpjL8DJhn9jP4DOPUgpbfJonk3SJ/Sw+Oi2hdoeHAv4HrzKUTRabR4ZV4ohni2RS3ojSSXn9TMY9Nbc0KOw/8mKlTHUeGL+pEamteWVYxr0yzLmYRmPFy/AyjTE/mJAJ+3nsnwaBpJXFZo9Vo90JjF/4isRRFvsl234S6SiL3eSveQoHyyCLzeaIxP40j83miER17mIPmNcF9mZ5bP53JVFfudjXvMYq0BvnsRkhJZKWi32+MHg36LjYr5hqBIaRi835usBBGuSx+cu9QmW6iX3GVONfdbGJHQxYaHtXn21j91hoe9dbudgstBWS1jb2Wxba3g3uNrE5PhBIR9vYTdZ+vlUegm3ss5QbaJ7VFpvYrP0E6rNd7Hvu6XgW3uxi307ew6vBxS72cQrPol3sww/w62OwiQ2Zv/KdW6Z7azpqAAAAAElFTkSuQmCC);
      background-size: 100% 100%;
      width: 362.76px;
      height: 61px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 4px;
      color: #fff;
      position: absolute;
      top: 330px;
      cursor: pointer;
    }
    .input-inner{
      display: inline-block;
      width: 100%;
      height: 51px;
      line-height: 1.5;
      padding: 4px 7px;
      font-size: 12px;
      border-radius: 4px;
      color: #515a6e;
      background-color: #fff;
      background-image: none;
      position: relative;
      cursor: text;
      transition: border .2s ease-in-out, background .2s ease-in-out, box-shadow .2s ease-in-out;
    }
    .ivu-input {
      display: inline-block;
      width: 100%;
      height: 44px;
      border-radius: 4px;
      color: #515a6e;
      background-color: #fff;
      background-image: none;
      position: relative;
      cursor: text;
      transition: border .2s ease-in-out, background .2s ease-in-out, box-shadow .2s ease-in-out;
    }
  }
}
</style>
