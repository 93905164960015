import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("data_token") || undefined,
  },
  mutations: {
    SET_TOKEN(state, obj) {
      localStorage.setItem("data_token", obj.token);
      state.token = obj.token;
    },
    CLEAR_TOKEN(state) {
      localStorage.removeItem("data_token");
      state.token = undefined;
    },
  },
  actions: {},
  modules: {},
});
