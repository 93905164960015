import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'provincial',
      component: () => import('@/views/home/index'),
      meta:{ keepAlive:true }
    },
      {
          path: '/city',
          name: 'city',
          component: () => import('@/views/city/index'),
      },
      {
          path: '/countyView',
          name: 'countyView',
          component: () => import('@/views/countyView/index'),
      }
  ]
})
