// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'

import iView from 'iview';
import 'iview/dist/styles/iview.css';//（无论全局还是局部都需要引入）
import './assets/css/reset.css' // 引入css样式
import './assets/css/index.css' // 引入css样式


import countTo from 'vue-count-to';
import ECharts from 'vue-echarts';


import "echarts";


import store from  './store' //引入store

import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {
    ak: 'WY0eETsbzRhOjG1OPQVPdaBCX3uPhY7l'
})

import scroll from 'vue-seamless-scroll'

import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

Vue.use(scroll)

Vue.use(iView);

Vue.component('countTo', countTo);
Vue.component('v-chart', ECharts);

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
