import request from "@/utils/request";

// 登录系统
export async function login(params) {
  return request.post("/datav/login", params);
}

// 退出系统
export async function logout(params) {
  return request.get("/datav/logout", params);
}

// 基础数据统计
export async function baseApi(params) {
  return request.post("/datav/base", params);
}

// 汇总金额
export async function amtApi(params) {
  return request.post("/datav/amt", params);
}

// 供应商供应情况统计
export async function merchantApi(params) {
  return request.post("/datav/merchant", params);
}


// 近12月交易额走势
export async function monthAmtApi(params) {
  return request.post("/datav/monthAmt", params);
}

// 供应商供应情况统计
export async function areaAmtApi(params) {
  return request.post("/datav/areaAmt", params);
}

// 里要贯材采购数量情况
export async function productApi(params) {
  return request.get("/datav/product", {params:params});
}

// 商品分类占比情况
export async function cateApi(params) {
  return request.post("/datav/cate", params);
}

// 存在过期食材学校预警
export async function expireApi(params) {
  return request.get("/datav/expire",  {params:params});
}

// 近几天未出库的学校预警
export async function unOutApi(params) {
  return request.get("/datav/unOut",  {params:params});
}

// 省级-市级地图图层数据
export async function mapApi(params) {
  return request.get("/datav/map",  {params:params});
}

export async function schoolApi(params) {
  return request.get("/datav/school",  {params:params});
}

export async function inApi(params) {
  return request.get("/datav/in",  {params:params});
}
export async function outApi(params) {
  return request.get("/datav/out",  {params:params});
}

export async function priceApi(params) {
  return request.get("/datav/price",  {params:params});
}

export async function outAndInApi(params) {
  return request.get("/datav/outin",  {params:params});
}

export async function inDetailApi(params) {
  return request.get("/datav/inDetail",  {params:params});
}
export async function outDetailApi(params) {
  return request.get("/datav/outDetail",  {params:params});
}

// 采购价格动态展示
export async function spriceApi(params) {
  return request.get("/datav/sprice", {params:params});
}
export async function mcntApi(params) {
  return request.get("/datav/mcnt", {params:params});
}






